import ClientLogin from "@pages/client/Auth/Login";
import ClientResetPassword from "@pages/client/Auth/ResetPassword";
import ClientForgotPassword from "@pages/client/Auth/ForgotPassword";
import { paths, IRoute, permissions } from "@router";
import Jobs from "@/pages/client/JobEvaluation";
import JobEvaluationSummary from "@/pages/client/JobEvaluation/JobEvaluationSummary";
import CreateJobEvaluation from "@/pages/client/JobEvaluation/CreateJobEvaluation";
import JobGradeDefination from "@/pages/client/JobGradeDefination";
import { UserRoles } from "@/constants";
import CompanyProfile from "@/pages/client/Companies/CompanyProfile";
import CompanyDetails from "@/pages/client/Companies/CompanyProfile/CompanyDetails";
import BusinessUnits from "@/pages/client/Companies/CompanyProfile/BusinessUnits";
import BusinessUnitRegions from "@/pages/client/Companies/CompanyProfile/BusinessUnits/BusinessUnitRegions";
import BusinessUnitCountries from "@/pages/client/Companies/CompanyProfile/BusinessUnits/BusinessUnitCountries";
import LegalEntities from "@/pages/client/Companies/CompanyProfile/BusinessUnits/LegalEntities";
import Regions from "@/pages/client/Companies/CompanyProfile/Regions";
import CreateCompany from "@/pages/client/Companies/CreateCompany";
import SubAdmins from "@/pages/client/SubAdmins";
import CreateSubAdmin from "@/pages/client/SubAdmins/CreateSubAdmin";

export const admin_routeConfig: IRoute[] = [];

export const client_routeConfig: IRoute[] = [
  // {
  //   path: paths.client.auth.login,
  //   component: ClientLogin,
  //   key: "Client Login",
  //   isPrivate: false,
  //   isCompany: true,
  //   allowedRoles: [],
  // },

  {
    path: paths.client.auth.forgot_password,
    component: ClientForgotPassword,
    key: "Client Forgot Password",
    isPrivate: false,
    isCompany: true,
    allowedRoles: [],
    exact: true,
  },
  {
    path: paths.client.auth.reset_password,
    component: ClientResetPassword,
    key: "Reset Password",
    isPrivate: false,
    isCompany: true,
    exact: true,

    allowedRoles: [],
  },

  {
    path: paths.client.sub_admins.listing,
    component: SubAdmins,
    key: "Sub Admins",
    breadcrumb: " Sub Admins",
    isPrivate: true,
    isCompany: true,
    exact: true,
    permission: permissions.VIEW_COMPANY_USER,
  },
  {
    path: paths.client.sub_admins.create,
    component: CreateSubAdmin,
    key: "Create Sub Admins",
    breadcrumb: "Sub Admins / Create Sub Admin",
    isPrivate: true,
    isCompany: true,
    exact: true,

    permission: permissions.CREATE_COMPANY_USER,
  },
  {
    path: paths.client.sub_admins.edit(),
    component: CreateSubAdmin,
    key: "Update Sub Admins",
    breadcrumb: "Sub Admins / Update Sub Admin",
    isPrivate: true,
    isCompany: true,
    exact: true,

    permission: permissions.UPDATE_COMPANY_USER,
  },

  {
    path: paths.client.jobEvaluation.listing,
    component: Jobs,
    key: "Job Creation",
    exact: true,
    breadcrumb: "Job Evaluations ",
    isPrivate: true,
    isCompany: true,
    permission: permissions.CREATE_JOB_FOR_EVALUATION,
    allowedRoles: [
      UserRoles.COMPANY_ADMIN,
      UserRoles.COMPANY_BUSINESS_ACCESS,
      UserRoles.COMPANY_SUPER_USER,
      UserRoles.COMPANY_USER,
    ],
  },
  {
    path: paths.client.jobEvaluation.create(),
    component: CreateJobEvaluation,
    key: "Evaluate Job",
    exact: true,
    breadcrumb: "Evaluate Job",
    isPrivate: true,
    isCompany: true,
    permission: permissions.CREATE_EVALUATION,
    allowedRoles: [
      UserRoles.COMPANY_ADMIN,
      UserRoles.COMPANY_BUSINESS_ACCESS,
      UserRoles.COMPANY_SUPER_USER,
      UserRoles.COMPANY_USER,
    ],
  },
  {
    path: paths.client.jobEvaluation.summary(),
    component: JobEvaluationSummary,
    key: "Job Evaluation Summary",
    exact: true,
    breadcrumb: "Evaluation Summary",
    isPrivate: true,
    isCompany: true,
    allowedRoles: [
      UserRoles.COMPANY_ADMIN,
      UserRoles.COMPANY_BUSINESS_ACCESS,
      UserRoles.COMPANY_SUPER_USER,
      UserRoles.COMPANY_USER,
    ],
  },
  {
    path: paths.client.jobGradeDefination.listing,
    component: JobGradeDefination,
    key: "Job Grade Defination",
    exact: true,
    breadcrumb: "Job Grade Definations",
    isPrivate: true,
    isCompany: true,
    permission: permissions.CREATE_EVALUATION,
    allowedRoles: [UserRoles.TOM_MASTER_USER],
  },
  {
    path: paths.client.company.profile.edit(),
    component: CreateCompany,
    key: "Edit Company",
    exact: true,
    breadcrumb: "Companies / Edit company",
    isPrivate: true,
  },
  {
    path: paths.client.company.profile.details(),
    component: CompanyProfile,
    key: "Companies",
    breadcrumb: "Company Details",
    isPrivate: true,
    routes: [
      {
        path: paths.client.company.profile.details(),
        component: CompanyDetails,
        key: "Companies Details",
        exact: true,
        breadcrumb: "Company Details",
        isPrivate: true,
      },
      {
        path: paths.client.company.profile.businessUnits.listing(),
        component: BusinessUnits,
        key: "Business Units",
        exact: true,
        breadcrumb: "Business Units",
        isPrivate: true,
      },
      {
        path: paths.client.company.profile.businessUnits.regions(),
        component: BusinessUnitRegions,
        key: "Business Unit Regions",
        exact: true,
        breadcrumb: "Business Unit Regions ",
        isPrivate: true,
      },
      {
        path: paths.client.company.profile.businessUnits.countries(),
        component: BusinessUnitCountries,
        key: "Business Unit Countries",
        exact: true,
        breadcrumb: "Companies list",
        isPrivate: true,
      },
      {
        path: paths.client.company.profile.businessUnits.legalEntries(),
        component: LegalEntities,
        key: "Legal Entities",
        exact: true,
        breadcrumb: "Companies list",
        isPrivate: true,
      },
      {
        path: paths.client.company.profile.regions(),
        component: Regions,
        key: "Regions",
        exact: true,
        breadcrumb: "Companies list",
        isPrivate: true,
      },
    ],
  },
];
