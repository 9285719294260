import { IJobEvaluationStages, JobEvaluationStages } from "@types";
import { createSlice } from "@reduxjs/toolkit";
import { jobEvaluationApi } from "@/services";
import { IDropDownNullOrString, IJobEvaluationDataResponse } from "./types";
import { evaluationModelOptions } from "@/constants";

type NameAndID = null | {
  name: string;
  id: number;
};

export type KeyAndDisplayName = null | {
  display_name: string;
  key: string;
};

interface IJobDetails {
  departmentName: string;
  business_unit?: number;
  region?: number;
  country_id?: number;
  evaluation_model: KeyAndDisplayName;
  noOfDirectReports: string;
  jobTitle: string;
  job_function: NameAndID;
  sub_job_function: NameAndID;
}

interface IEvaluationInformation {
  evaluation_date: string;
  evaluation_model: KeyAndDisplayName;
}

interface IKnowledgeSkillAndApplication {
  knowledge_and_skill: KeyAndDisplayName;
  application: KeyAndDisplayName;
  minimum_level_education: string;
  minimum_level_education_reason: string;
  educational_degree: string;
}

interface IStakeHolderManagement {
  communication_nature: KeyAndDisplayName;
  complexity: KeyAndDisplayName;
}

interface IProblemSolvingSolutioning {
  problem_solving_solutioning: KeyAndDisplayName;
}

interface IDecisionMakingImpact {
  role_impact_on_organisation: KeyAndDisplayName;
  impact_of_decision: KeyAndDisplayName;
}

interface IFinancialResponsibility {
  non_financial_responsibility: KeyAndDisplayName;
  revenue_responsibility: KeyAndDisplayName;
}

interface IInitialState {
  id: number | undefined;
  jobDetails: IJobDetails;
  evaluationInformation: IEvaluationInformation;
  knowledgeSkillAndApplication: IKnowledgeSkillAndApplication;
  problemSolvingSolutioning: IProblemSolvingSolutioning;
  stakeHolderManagement: IStakeHolderManagement;
  decisionMakingImpact: IDecisionMakingImpact;
  financialResponsibility: IFinancialResponsibility;
  jobEvaluationSteps: Partial<IJobEvaluationStages> | null;
  evaluation_result?: null;
  evaluation_date?: null;
  updated_at?: Date;
  status?: IDropDownNullOrString;
  jobEvaluationListingPage: number;
  je_form?: string;
  organization_chart?: string;
}

export interface IAppState {
  jobDetails: IJobDetails;
  evaluationInformation: IEvaluationInformation;
  knowledgeSkillAndApplication: IKnowledgeSkillAndApplication;
  problemSolvingSolutioning: IProblemSolvingSolutioning;
  stakeHolderManagement: IStakeHolderManagement;
  decisionMakingImpact: IDecisionMakingImpact;
  financialResponsibility: IFinancialResponsibility;
}

const initialState: IInitialState = {
  id: undefined,
  jobDetails: {
    departmentName: "",
    business_unit: undefined,
    region: undefined,
    country_id: undefined,
    evaluation_model: null,
    noOfDirectReports: "",
    jobTitle: "",
    job_function: null,
    sub_job_function: null,
  },
  evaluationInformation: {
    evaluation_date: "",
    evaluation_model: null,
  },
  knowledgeSkillAndApplication: {
    knowledge_and_skill: null,
    application: null,
    minimum_level_education: "",
    minimum_level_education_reason: "",
    educational_degree: "",
  },
  problemSolvingSolutioning: {
    problem_solving_solutioning: null,
  },

  stakeHolderManagement: {
    communication_nature: null,
    complexity: null,
  },
  decisionMakingImpact: {
    role_impact_on_organisation: null,
    impact_of_decision: null,
  },
  financialResponsibility: {
    non_financial_responsibility: null,
    revenue_responsibility: null,
  },

  jobEvaluationSteps: {
    stage: JobEvaluationStages.EVALUATION_INFORMATION_STEP,
    is_active: true,
  },
  jobEvaluationListingPage: 1,
  je_form: "",
  organization_chart: "",
};

const jobEvaluationSlice = createSlice({
  name: "jobEvaluation",
  initialState,
  reducers: {
    resetJobDetails: (state, action) => {
      state.jobDetails = { ...initialState.jobDetails };
    },
    handleJobDetails: (state, action) => {
      state.jobDetails = { ...state.jobDetails, ...action.payload };
    },
    handleEvaluationInformation: (state, action) => {
      state.evaluationInformation = {
        ...state.evaluationInformation,
        ...action.payload,
      };
    },
    handleKnowledgeSkillAndApplication: (state, action) => {
      state.knowledgeSkillAndApplication = {
        ...state.knowledgeSkillAndApplication,
        ...action.payload,
      };
    },
    handleProblemSolvingSolutioning: (state, action) => {
      state.problemSolvingSolutioning = {
        ...state.problemSolvingSolutioning,
        ...action.payload,
      };
    },
    handleStakeHolderManagement: (state, action) => {
      state.stakeHolderManagement = {
        ...state.stakeHolderManagement,
        ...action.payload,
      };
    },
    handleDecisionMakingImpact: (state, action) => {
      state.decisionMakingImpact = {
        ...state.decisionMakingImpact,
        ...action.payload,
      };
    },
    handleFinancialResponsibility: (state, action) => {
      state.financialResponsibility = {
        ...state.financialResponsibility,
        ...action.payload,
      };
    },
    handleStage: (state, action: { payload: JobEvaluationStages }) => {
      state.jobEvaluationSteps = {
        ...state?.jobEvaluationSteps,
        stage: action.payload,
      };
    },
    // resetJobEvaluationAll: () => initialState,
    resetJobEvaluationAll: () => initialState,
    handlePageChange: (state, { payload }) => {
      state.jobEvaluationListingPage = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        jobEvaluationApi.endpoints.createJobForEvaluation.matchFulfilled,
        (state, { payload }) => {
          state.id = payload.data.id;
          state.status = payload.data.status;
          state.updated_at = payload.data.updated_at;
          state.jobEvaluationListingPage = 1;
        }
      )
      .addMatcher(
        jobEvaluationApi.endpoints.createJobEvaluation.matchFulfilled,
        (state, { payload }) => {
          state.jobEvaluationListingPage = 1;
        }
      )
      .addMatcher(
        jobEvaluationApi.endpoints.fetchJobEvaluationById.matchFulfilled,
        (state, { payload }) => {
          const responseData: IJobEvaluationDataResponse = payload.data;

          const jobEvaluationModelArray = evaluationModelOptions.filter(
            option => option.key === responseData.evaluation_model?.key!
          );
          const jobEvaluationModel = jobEvaluationModelArray.length
            ? jobEvaluationModelArray[0]
            : null;

          state.id = responseData.id;

          state.jobDetails = {
            noOfDirectReports: responseData.no_direct_report!,
            jobTitle: responseData.job_title!,
            departmentName: responseData.department_name!,
            job_function: responseData.job_function
              ? {
                  id: responseData.job_function?.id!,
                  name: responseData.job_function?.name!,
                }
              : null,
            sub_job_function:
              {
                id: responseData.sub_job_function?.id!,
                name: responseData.sub_job_function?.name!,
              } || null,
            evaluation_model: jobEvaluationModel,
          };

          state.knowledgeSkillAndApplication = {
            application: {
              key: responseData.knowledge_skills_application?.key!,
              display_name:
                responseData.knowledge_skills_application?.display_name!,
            },
            knowledge_and_skill: {
              key: responseData.knowledge_skills?.key!,
              display_name: responseData.knowledge_skills?.display_name!,
            },
            minimum_level_education:
              responseData.minimum_level_of_education_required!,
            minimum_level_education_reason:
              responseData.why_is_this_level_of_education_required!,
            educational_degree: responseData.area_of_specializations_or_degree!,
          };

          state.problemSolvingSolutioning = {
            problem_solving_solutioning: {
              key: responseData.problem_solving_solutioning?.key!,
              display_name:
                responseData.problem_solving_solutioning?.display_name!,
            },
          };

          state.stakeHolderManagement = {
            communication_nature: {
              key: responseData.nature_of_communication?.key!,
              display_name: responseData.nature_of_communication?.display_name!,
            },
            complexity: {
              key: responseData.complexity?.key!,
              display_name: responseData.complexity?.display_name!,
            },
          };

          state.decisionMakingImpact = {
            impact_of_decision: {
              key: responseData.impact_of_decision_making?.key!,
              display_name:
                responseData.impact_of_decision_making?.display_name!,
            },
            role_impact_on_organisation: {
              key: responseData.role_impact_on_organization?.key!,
              display_name:
                responseData.role_impact_on_organization?.display_name!,
            },
          };

          state.financialResponsibility = {
            non_financial_responsibility: {
              key: responseData.non_financial_responsibility?.key!,
              display_name:
                responseData.non_financial_responsibility?.display_name!,
            },
            revenue_responsibility: {
              key: responseData.revenue_responsibility?.key!,
              display_name: responseData.revenue_responsibility?.display_name!,
            },
          };

          state.status = responseData.status;
          state.evaluation_date = responseData.evaluation_date;
          state.evaluation_result = responseData.evaluation_result;
          state.updated_at = responseData.updated_at;
          state.je_form = responseData.je_form;
          state.organization_chart = responseData.organization_chart;
        }
      );
  },
});

export const {
  resetJobDetails,
  handleJobDetails,
  handleEvaluationInformation,
  handleKnowledgeSkillAndApplication,
  handleProblemSolvingSolutioning,
  handleStakeHolderManagement,
  handleDecisionMakingImpact,
  handleFinancialResponsibility,
  handleStage,
  resetJobEvaluationAll,
  handlePageChange,
} = jobEvaluationSlice.actions;

export default jobEvaluationSlice.reducer;
